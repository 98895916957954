
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class VideoModal extends Vue {

    @Prop() reason: string;
    @Prop() userCompleteName: string;

    created() {}

}
