
import { Options, Vue } from 'vue-class-component';
import { AppUserClient } from '@/services/Services';
import { ExportServices } from '@/services/ExportServices';
import VideoModal from '../modals/videoModal.vue';
import ReasonModal from '../modals/reasonModal.vue';

import * as OM from '@/Model';

@Options({})
export default class utentiList extends Vue {

    items: OM.UserBasicInfoVM[] = [];

    created(){
        this.init();
    }

    init() {
        AppUserClient.getAllAppUsers(this.$route.params.eventIdentifier as string)
        .then(x => {
            this.items = x;
        })
    }

    exportExcel(){
        AppUserClient.exportUsers()
        .then(x => {
            ExportServices.DownloadExport(x.identifier, x.text);
        })
    }

    openVideoModal(identifier: string, name: string, videoUrl: string) {
        this.$opModal.show(VideoModal, {
            userCompleteName: name,
            videoUrl: videoUrl,
            removeVideo: (reason: string) => {
                var model = new OM.TextIdentifier();
                model.identifier = identifier;
                model.text = reason;

                AppUserClient.removeVideoFromUser(model)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }

    openMotivationModal(userReason: string,  name: string) {
        this.$opModal.show(ReasonModal, {
            userCompleteName: name,
            reason: userReason
        })
    }

}
